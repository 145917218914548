const menuItems = [
  {
    id: 1,
    label: "DASHBOARD",
    icon: "bx-home-circle",
    link: "/",
  },
  {
    id: 2,
    label: "ARSIP DOKUMEN",
    icon: "bx-envelope",
    subItems: [
      {
        id: 42,
        label: "Surat Masuk",
        link: "/surat-masuk",
        parentId: 2,
      },
      {
        id: 43,
        label: "Surat Keluar",
        link: "/surat-keluar",
        parentId: 2,
      },
    ],
  },
  {
    id: 5,
    label: "ADMINISTRASI RAPAT",
    icon: "bx-task",
    subItems: [
      {
        id: 6,
        label: "Absensi Rapat Direksi",
        link: "/administrasi/rapat-direksi",
        parentId: 5,
      },
      {
        id: 7,
        label: "Absensi Rapat Komite Direksi",
        link: "/administrasi/rapat-komite-direksi",
        parentId: 5,
      },
      {
        id: 8,
        label: "Absensi Rapat Komisaris",
        link: "/administrasi/rapat-komisaris",
        parentId: 5,
      },
      {
        id: 9,
        label: "Absensi Rapat Gabungan",
        link: "/administrasi/rapat-gabungan",
        parentId: 5,
      },
    ],
  },
  {
    id: 55,
    label: "DIGITALISASI",
    icon: "bx-bar-chart-alt-2",
    subItems: [
      {
        id: 37,
        label: "Digitalisasi CV Direksi & Dekom",
        link: "/digitaliasi/cv",
        parentId: 55,
      },
      {
        id: 54,
        label: "Digitalisasi SPK",
        link: "/digitaliasi/spk",
        parentId: 55,
      },
    ],
  },
  {
    id: 13,
    label: "MONITORING",
    icon: "bx-calendar",
    subItems: [
      {
        id: 14,
        label: "Sponsorship",
        link: "/monitoring/sponsorship",
        parentId: 13,
      },
      {
        id: 53,
        label: "Vendor/Konsultan",
        link: "/monitoring/vendor",
        parentId: 13,
      },
      {
        id: 16,
        label: "Notulen Rapat Direksi",
        link: "/monitoring/notulen",
        parentId: 13,
      },
    ],
  },
];

const menuItemsNoAdmin = [
  {
    id: 2,
    label: "ARSIP DOKUMEN",
    icon: "bx-envelope",
    subItems: [
      {
        id: 42,
        label: "Surat Masuk",
        link: "/surat-masuk",
        parentId: 2,
      },
      {
        id: 43,
        label: "Surat Keluar",
        link: "/surat-keluar",
        parentId: 2,
      },
    ],
  },
  {
    id: 55,
    label: "DIGITALISASI",
    icon: "bx-bar-chart-alt-2",
    subItems: [
      {
        id: 37,
        label: "Digitalisasi CV Direksi & Dekom",
        link: "/digitaliasi/cv",
        parentId: 55,
      },
      {
        id: 54,
        label: "Digitalisasi SPK",
        link: "/digitaliasi/spk",
        parentId: 55,
      },
    ],
  },
  {
    id: 13,
    label: "MONITORING",
    icon: "bx-calendar",
    subItems: [
      {
        id: 53,
        label: "Vendor/Konsultan",
        link: "/monitoring/vendor",
        parentId: 13,
      },
    ],
  },
];

const menuItemsSekDivisi = [
  {
    id: 2,
    label: "ARSIP DOKUMEN",
    icon: "bx-envelope",
    subItems: [
      {
        id: 42,
        label: "Surat Masuk",
        link: "/surat-masuk",
        parentId: 2,
      },
      {
        id: 43,
        label: "Surat Keluar",
        link: "/surat-keluar",
        parentId: 2,
      },
    ],
  },
  {
    id: 55,
    label: "DIGITALISASI",
    icon: "bx-bar-chart-alt-2",
    subItems: [
      {
        id: 37,
        label: "Digitalisasi CV Direksi & Dekom",
        link: "/digitaliasi/cv",
        parentId: 55,
      },
      {
        id: 54,
        label: "Digitalisasi SPK",
        link: "/digitaliasi/spk",
        parentId: 55,
      },
    ],
  },
  {
    id: 13,
    label: "MONITORING",
    icon: "bx-calendar",
    subItems: [
      {
        id: 53,
        label: "Vendor/Konsultan",
        link: "/monitoring/vendor",
        parentId: 13,
      },
      {
        id: 16,
        label: "Notulen Rapat Direksi",
        link: "/monitoring/notulen",
        parentId: 13,
      },
    ],
  },
];

export { menuItems, menuItemsNoAdmin, menuItemsSekDivisi };
